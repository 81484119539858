import React  from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Section from "./Section";

const Styles = styled.div`
    .image-grid {
        padding: 0 1.25rem;
    }

    .title {
        margin: 0 0 1.25rem;
    }

    .container {
        display: inline-block;
    }

    .grid-container {
        list-style: none;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-gap: 1.25rem;
    }

    .grid-item {
        background-size: cover;
        background-repeat: no-repeat;
        height: 15.75rem;
        width: 100%;
        box-shadow: 0 0 1.25rem rgb(0 0 0 / 30%);
        border-radius: .25rem;
        background-position: center;

        a {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            justify-content: flex-end;
            text-decoration: none;
            padding: 1.188rem;
            font-size: 1.5rem;
            box-sizing: border-box;
            color: var(--white);
            position: relative;
            background: rgba(0, 0, 0, 0.4);
        }

        .bg-image {
            position: absolute!important;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
        }
    }


    @media only screen and (min-width: 1024px) {

        .image-grid {
            padding: 0;
        }

        .container {
            display: inline-block;
            margin-left: 50%;
            transform: translate(-50%);
        }

        .grid-container {
            grid-template-columns: 1fr 1fr 1fr;
        }

        .grid-item {
            height: 18.75rem;

            &:hover,
            &:focus-within {
                transform: scale(1.025);
                transform-origin: 50% 50%;
                transition: transform .3s ease-out;
            }
        }
    }

`;

const ImageGrid = (props) => {
    const {gridItems} = props;

    return (
        <Section id={ props.id ? props.id : null }>
            <Styles>
                <section className="image-grid">
                    <div className="container">
                        <div className="col-1 hidden-m"/>
                        <div className="col-10">
                            {props.title ? <h2 className="title">{props.title}</h2> : null}
                            <ul className="grid-container">
                                {gridItems.map((item, index) => {
                                    return (
                                        <li key={index} className="grid-item">
                                            <Link to={item.link}>
                                                <Img className="bg-image" fluid={item.image.asset.fluid}/>
                                                <p>{item.copy}</p>
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </section>
            </Styles>
        </Section>
    );
};

export default ImageGrid;